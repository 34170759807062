import React, { Suspense } from "react"
import HeroBlock from "../Core/HeroBlock"
import styled from "styled-components"
import Fallback from "../Core/Fallback"
import FeaturesTicker from "./FeaturesTicker"
import ClickableHeroSlide from "../Homepage/ClickableHeroSlide"
import BlackFridayHero from "../Homepage/BlackFridayHero"
import CyberMondayHero from "../Homepage/CyberMondayHero"
import HeroBlockAllProduct from "../Core/HeroBlockAllProduct"

const QuizCtaBlock = React.lazy(() => import("../Core/QuizCtaBlock"))
const ProductsCallout = React.lazy(() => import("./ProductsCallout"))
const ReviewCarousel = React.lazy(() => import("./ReviewCarousel"))

const Background = styled.div`
  background-image: linear-gradient(0deg,#fff 43%,#fffffe 0,#f7f5f0 86%);
`

const PageContents = ({
  blocks,
  features,
  allProductsPlp,
}) => {
  let pageBlocks = []
  const isSSR = typeof window === "undefined"


  blocks.forEach((block, index) => {
    let { __typename: type } = block
    const nextBlock = blocks[index + 1]
    let isQuizNext = nextBlock && nextBlock.__typename === "ContentfulQuizCallout"

    if (type === "ContentfulHeroBlock") {
      // if (allProductsPlp) {        
      //   pageBlocks.push(<ClickableHeroSlide isPlp={true} key={index} />)
      // } else {
        pageBlocks.push(<HeroBlockAllProduct {...block} key={index} />)
      // }

      pageBlocks.push(
        <FeaturesTicker features={features} key={`${index}-1`} />
      )
    }
    if (type === "ContentfulProductsCallout") {
      pageBlocks.push(<React.Fragment key={index}>{!isSSR && <Suspense fallback={<Fallback/>}><ProductsCallout {...block} quizNext={isQuizNext} /></Suspense>}</React.Fragment>)
    }
    if (type === "ContentfulReviewsCarousel") {
      pageBlocks.push(<React.Fragment key={index}>{!isSSR && <Suspense fallback={<Fallback/>}><ReviewCarousel {...block} quizNext={isQuizNext} /></Suspense>}</React.Fragment>)
    }
    if (type === "ContentfulQuizCallout") {
      pageBlocks.push(<React.Fragment key={index}>{!isSSR && <Suspense fallback={<Fallback/>}><QuizCtaBlock {...block} plp /></Suspense>}</React.Fragment>)
    }
  })

  return (
    <React.Fragment>
      <Background>{pageBlocks}</Background>
    </React.Fragment>
  )
}

export default PageContents
