import React from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Core/Seo"
import { graphql } from "gatsby"
import PageContents from "../components/PLP/PageContents"


const CollectionTemplate = (props) => {
  const path = props.location.pathname
  const { page, features, buttons } = props.data
  const {
    metaTitle,
    metaDescription,
    socialMediaImage,
    blocks,
  } = page

  let sharingImage = false

  if (socialMediaImage && socialMediaImage.file) {
    sharingImage = socialMediaImage.file.url
  }

  const pageContentsProps = {
    blocks,
    features: features.nodes
  }

  const isAllProductsPage = page.slug === "all-products"

  return (
    <Layout transparentHeader={isAllProductsPage? false : true}>
      <Seo
        path={path}
        title={metaTitle}
        description={metaDescription}
        fullTitle={false}
        image={sharingImage}
      />
      <PageContents {...pageContentsProps} allProductsPlp={isAllProductsPage ? true : false}/>
    </Layout>
  )
}

export default CollectionTemplate

export const pageQuery = graphql`
  query CollectionTemplateQuery($slug: String!) {
    page: contentfulPlp(slug: { eq: $slug }) {
      id
      metaTitle
      metaDescription
      socialMediaImage {
        file {
          url
        }
      }
      slug
      blocks {
        ... heroFragment
        ... quizCalloutFragment
        ... carouselFragment
        ... productsCalloutFragment
      }
    }
    features :  allContentfulFeature(sort: {fields: order}) {
      nodes {
        ... featureFragment
      }
    }
    
  }
`
